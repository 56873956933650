import { useEffect, useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
import { Collapse } from 'bootstrap'; // Import the Collapse component
import $ from 'jquery';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import UIDesign from '../assets/Images/ui-design.png';
import DigitalMarketing from '../assets/Images/digital-marketing.png'
import '../assets/css/style.css';
import Logo from "../assets/Images/logo.png"
import axios from "axios";
import Form from './contact-us/form';

function Home() {

    useEffect(() => {
        // Navigation background color change on scroll
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 450) {
                $('.navbar-fixed-top').addClass('opaque');
            } else {
                $('.navbar-fixed-top').removeClass('opaque');
            }
        });

        // Hide responsive navigation on click using Bootstrap 5 collapse
        $(".navbar-nav li a").on('click', function () {
            const navbarCollapse = document.getElementById('navbarScroll');
            const bootstrapCollapse = new Collapse(navbarCollapse, {
                toggle: false
            });
            bootstrapCollapse.hide();
        });

        // Smooth scrolling
        $(".navbar-nav li a, a.scrool").on('click', function (e) {
            e.preventDefault();
            const full_url = this.href;
            const parts = full_url.split("#");
            const trgt = parts[1];
            const target_offset = $("#" + trgt).offset();
            const target_top = target_offset.top;
            $('html,body').animate({ scrollTop: target_top - 70 }, 1000);
        });

        // Cleanup jQuery event handlers on unmount
        return () => {
            $(window).off('scroll');
            $(".navbar-nav li a").off('click');
            $(".navbar-nav li a, a.scrool").off('click');
        };
    }, []);

    // Subscribe email code start here.....
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    // Update email state and reset messages
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError("");
        setSuccessMessage("");
    };

    // Email validation function
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        // User-side validation
        if (!email) {
            setError("Email field is required.");
            return;
        }

        if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Data to be sent to the API
        const postData = {
            mail_templateID: 1,
            user_email: email,
            mail_type: "WELCOME",
            mail_src_website: "foipin.com",
            mail_footer: "007,dubai",
            user_web_link: "foipin:6000.com",
        };

        try {
            const response = await axios.post(
                "https://emails.foipin.com/email/send_mail",
                postData
            );

            if (response.data.result && response.data.result.status === "OK") {
                setSuccessMessage("Subscription successful! Please check your email.");
                setEmail(""); // Clear email input
            } else {
                throw new Error(response.data.error.error_message || "Unknown error");
            }
        } catch (error) {
            setError(
                error.response?.data?.error?.error_message ||
                "Something went wrong. Please try again later."
            );
        }
    };
    // Subscribe email code end here.....

    return (
        <>
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg navbar-fixed-top">
                        <div className="container">
                            <a className="navbar-brand" href="#"><img className='logo' src={Logo} /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"><i className="bi bi-list" /></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarScroll">
                                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll justify-content-center">
                                    <li className="nav-item"><a className="nav-link" href="#home">Home</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#about">Why We</a></li>
                                    {/* <li className="nav-item"><a className="nav-link" href="#team">Our Team</a></li> */}
                                    <li className="nav-item"><a className="nav-link" href="#how-it-works">How It Works</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#pricing">Pricing</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#blog">Blog</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
                                </ul>
                                <div className="col-md-3 text-end">
                                    <a href="https://auth.foipin.com/login"><button type="button" className="btn btn-link"><i className="bi bi-person" /> Login</button></a>
                                    <a href="https://auth.foipin.com/register"><button type="button" className="btn btn-primary">Sign-up</button></a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                {/* end header */}
                <main>
                    {/*begin home section */}
                    <section className="home-section" id="home">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row align-items-center">
                                {/*begin col-md-6*/}
                                <div className="col-md-6">
                                    <h1>Online Faxing from anywhere to everywhere <i className="bi bi-chat-left-quote green" /></h1>
                                    <h3>Simple | Smart | Secure | Scalable </h3>
                                    <br /><br />
                                    <ul className="benefits">
                                        <li><i className="bi bi-check blue" />High performance world class Fax over IP protocol</li>
                                        <li><i className="bi bi-check blue" />API powered, integrate with your apps, EMR or EHR</li>
                                        <li><i className="bi bi-check blue" />Bulk Faxing, highly scalable capacity</li>
                                        <li><i className="bi bi-check blue" />Top notch quality with competitive pricing</li>
                                    </ul>
                                    <h3>Let's Fax ! </h3>
                                    {/*begin newsletter_form_box */}
                                    <div className="newsletter_form_box">
                                        {/*begin success_box */}
                                        <p className="newsletter_success_box" style={{ display: 'none' }}>We received your message and you'll hear from us soon. Thank You!</p>
                                        {/*end success_box */}
                                        {/*begin newsletter-form */}
                                        <form id="newsletter-form" className="newsletter-form" onSubmit={handleSubmit}>
                                            {/* Email Input */}
                                            <input
                                                id="email_newsletter"
                                                type="text"
                                                name="nf_email"
                                                placeholder="Enter Your Email Address"
                                                value={email}
                                                onChange={handleEmailChange}
                                            />
                                            {/* Submit Button */}
                                            <button type="submit" id="submit-button-newsletter">
                                                SUBSCRIBE
                                            </button>

                                        </form>
                                        {/* Validation/Error Message */}
                                        {error && <p style={{ color: "red" }}>{error}</p>}
                                        {/* Success Message */}
                                        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
                                        {/*end newsletter-form */}
                                        <p className="newsletter-form-terms">
                                            <span><i className="bi bi-check2" /> HIPPA Compliant</span>
                                            <span><i className="bi bi-check2" /> Over 95 % Delivery Rates</span>
                                        </p>
                                    </div>
                                    {/*end newsletter_form_box */}
                                </div>
                                {/*end col-md-6*/}
                                {/*begin col-md-5*/}
                                <div className="col-md-6">
                                    <img src={UIDesign} className="hero-image width-100 margin-top-20" alt="pic" />
                                </div>
                                {/*end col-md-5*/}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end home section */}
                    {/*begin section-white */}
                    <section className="section-white" id="about">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-12 */}
                                <div className="col-md-12 text-center">
                                    <h2>Why we ? What makes us different</h2>
                                </div>
                                {/*end col-md-12 */}
                                {/*begin col-md-4 */}
                                <div className="col-md-4">
                                    <div className="main-services">
                                        <i className="bi bi-megaphone green" />
                                        <h4>P2P Faxing</h4>
                                        <p>Our Fax over IP protocl can go peer to peer(P2P). This implies that you really don't need a faxing number on our network when faxing On-Net. On-net Faxing is half the price and even more secure.</p>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4 */}
                                <div className="col-md-4">
                                    <div className="main-services">
                                        <i className="bi bi-gem red" />
                                        <h4>Virtual Fax Machines</h4>
                                        <p>We provide virtual fax machines that can bridge over IP networks for sending and receiving faxes. This is a virtual fax device that functions like a physical fax machine in any office.</p>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4 */}
                                <div className="col-md-4">
                                    <div className="main-services">
                                        <i className="bi bi-speedometer2 blue" />
                                        <h4>Multi-carrier Switching</h4>
                                        <p>We have an array of top Tier 1 carriers that ensure delivery of your faxes. Our delivery rates are high as you can switch between different carriers. This also gives you automated failover routes.</p>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end section-white */}
                    {/*begin testimonials section */}
                    <section className="section-grey small-padding-bottom">
                        {/*begin container */}
                        <div className="container" style={{ overflow: 'hidden' }}>
                            {/*begin row */}
                            <div className="row">
                                {/*begin col md 12 */}
                                <div className="col-md-12 mx-auto padding-top-10 padding-bottom-30">
                                    {/*begin testimonials carousel */}
                                    <div id="carouselTestimonials" className="carousel slide" data-bs-ride="carousel">
                                        {/*begin carousel-indicators */}
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to={1} aria-label="Slide 2" />
                                            {/* <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to={2} aria-label="Slide 3" /> */}
                                        </div>
                                        {/*end carousel-indicators */}
                                        {/*begin carousel-inner */}
                                        <div className="carousel-inner">
                                            {/*begin carousel-item */}
                                            <div className="carousel-item active">
                                                {/*begin row */}
                                                <div className="row align-items-center testim-inner">
                                                    {/*begin col-md-6 */}
                                                    <div className="col-md-6">
                                                        {/*begin video-popup-wrapper*/}
                                                        <div className="video-popup-wrapper margin-right-25">
                                                            {/*begin popup-gallery*/}
                                                            <div className="popup-gallery">
                                                                <iframe
                                                                    className="video-frame"
                                                                    src="https://www.youtube.com/embed/FPfQMVf4vwQ"
                                                                    title="YouTube video"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                ></iframe>
                                                            </div>
                                                            {/*end popup-gallery*/}
                                                        </div>
                                                        {/*end video-popup-wrapper*/}
                                                    </div>
                                                    {/*end col-md-6 */}
                                                    {/*begin col-md-6 */}
                                                    <div className="col-md-6 testim-info">
                                                        <i className="bi bi-chat-left-quote green" />
                                                        <p>Nemo enimat ipsam voluptatem quia voluptas situm ets aspernatis netsum loris fugit, sed quia magnitus honoratis quis ratione sequi etum nets.</p>
                                                        <h6>Jennifer Smith<span>  — Designer <span className="red">@EpicThemes</span></span></h6>
                                                    </div>
                                                    {/*end col-md-5 */}
                                                </div>
                                                {/*end row */}
                                            </div>
                                            {/*end carousel-item */}
                                            {/*begin carousel-item */}
                                            <div className="carousel-item">
                                                {/*begin row */}
                                                <div className="row align-items-center testim-inner">
                                                    {/*begin col-md-6 */}
                                                    <div className="col-md-6">
                                                        {/*begin video-popup-wrapper*/}
                                                        <div className="video-popup-wrapper margin-right-25">
                                                            {/*begin popup-gallery*/}
                                                            <div className="popup-gallery">
                                                                <iframe
                                                                    className="video-frame"
                                                                    src="https://www.youtube.com/embed/FPfQMVf4vwQ"
                                                                    title="YouTube video"
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                ></iframe>
                                                            </div>
                                                            {/*end popup-gallery*/}
                                                        </div>
                                                        {/*end video-popup-wrapper*/}
                                                    </div>
                                                    {/*end col-md-6 */}
                                                    {/*begin col-md-6 */}
                                                    <div className="col-md-6 testim-info">
                                                        <i className="bi bi-chat-left-quote green" />
                                                        <p>Nemo enimat ipsam voluptatem quia voluptas situm ets aspernatis netsum loris fugit, sed quia magnitus honoratis quis ratione sequi etum nets.</p>
                                                        <h6>John Doe<span>  — General Manager <span className="red">@BeSmart</span></span></h6>
                                                    </div>
                                                    {/*end col-md-5 */}
                                                </div>
                                                {/*end row */}
                                            </div>
                                            {/*end carousel-item */}
                                            {/*begin carousel-item */}
                                            {/* <div className="carousel-item"> */}
                                            {/*begin row */}
                                            {/* <div className="row align-items-center testim-inner"> */}
                                            {/*begin col-md-6 */}
                                            {/* <div className="col-md-6"> */}
                                            {/*begin video-popup-wrapper*/}
                                            {/* <div className="video-popup-wrapper margin-right-25"> */}
                                            {/*begin popup-gallery*/}
                                            {/* <div className="popup-gallery">
                                                                <img src="http://placehold.it/555x365" alt="testimonials" className="width-100 image-shadow video-popup-image responsive-bottom-margins" />
                                                                <a className="popup4 video-play-icon" href="https://www.youtube.com/watch?v=FPfQMVf4vwQ">
                                                                    <i className="bi bi-caret-right-fill" />
                                                                </a>
                                                            </div> */}
                                            {/*end popup-gallery*/}
                                            {/* </div> */}
                                            {/*end video-popup-wrapper*/}
                                            {/* </div> */}
                                            {/*end col-md-6 */}
                                            {/*begin col-md-6 */}
                                            {/* <div className="col-md-6 testim-info">
                                                        <i className="bi bi-chat-left-quote green" />
                                                        <p>Nemo enimat ipsam voluptatem quia voluptas situm ets aspernatis netsum loris fugit, sed quia magnitus honoratis quis ratione sequi etum nets.</p>
                                                        <h6>Emily Richards<span>  — Copywriter <span className="red">@Books-Online</span></span></h6> */}
                                            {/* </div> */}
                                            {/*end col-md-5 */}
                                            {/* </div> */}
                                            {/*end row */}
                                            {/* </div> */}
                                            {/*end carousel-item */}
                                        </div>
                                        {/*end carousel-inner */}
                                    </div>
                                    {/*end testimonials carousel */}
                                </div>
                                {/*end col md 12*/}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end testimonials section */}
                    {/*begin section-grey */}
                    <section className="section-grey small-padding-top">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-12 */}
                                <div className="col-md-12 text-center padding-bottom-20">
                                    <h3>Trusted By Over 1,000+ Businesses</h3>
                                </div>
                                {/*end col-md-12 */}
                            </div>
                            {/*end row */}
                            {/*begin row */}
                            <div className="row justify-content-center">
                                {/*begin col-md-1 */}
                                <div className="col-md-1" />
                                {/*end col-md-1 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-1 */}
                                <div className="col-md-1 d-none d-md-block" />
                                {/*end col-md-1 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                                {/*begin col-md-2 */}
                                <div className="col-md-2 col-xs-6">
                                    <div className="our-partners">
                                        <img src="http://placehold.it/316x95" className="width-100" alt="pic" />
                                    </div>
                                </div>
                                {/*end col-md-2 */}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end section-grey */}
                    {/*begin section-white */}
                    <section className="section-white">
                        {/*begin container*/}
                        <div className="container">
                            {/*begin row*/}
                            <div className="row align-items-center">
                                {/*begin col-md-6*/}
                                <div className="col-md-6">
                                    <h3>Discover how we can help you to grow your business fast.</h3>
                                    <p>Anticas quia voluptas sit aspernatur netsum demo ets ipsam voluptatem lorem fugit, seditum netis velas net nesciunt.</p>
                                    <ul className="benefits">
                                        <li><i className="bi bi-check blue" />Quias netus magni netsum qui ratione sequi.</li>
                                        <li><i className="bi bi-check blue" />Venis ratione sequi netus enim tempor magni.</li>
                                        <li><i className="bi bi-check blue" />Enim ipsam netus voluptatem voluptas.</li>
                                    </ul>
                                    <a href="#" className="scrool"><button type="button" className="btn btn-lg btn-primary">Discover More</button></a>
                                </div>
                                {/*end col-md-6*/}
                                {/*begin col-md-6*/}
                                <div className="col-md-6">
                                    <img src={DigitalMarketing} className="width-100 responsive-top-margins" alt="pic" />
                                </div>
                                {/*end col-sm-6*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/*end section-white*/}
                    {/*begin fun-facts */}
                    <section className="section-grey medium-padding-bottom">
                        {/*begin container*/}
                        <div className="container">
                            {/*begin row*/}
                            <div className="row">
                                {/*begin col md 7 */}
                                <div className="col-md-7 mx-auto margin-bottom-10 text-center">
                                    <h3>Fun Facts, how are we doing ?</h3>
                                </div>
                                {/*end col md 7*/}
                            </div>
                            {/*end row*/}
                            {/*begin row*/}
                            <div className="row">
                                {/*begin fun-facts-box */}
                                <div className="col-md-2 offset-md-1 fun-facts-box blue text-center">
                                    <i className="bi bi-gift-fill" />
                                    <p className="fun-facts-title"><span className="facts-numbers">10M+</span><br />Faxes Processed</p>
                                </div>
                                {/*end fun-facts-box */}
                                {/*begin fun-facts-box */}
                                <div className="col-md-2 fun-facts-box red text-center">
                                    <i className="bi bi-heart-fill" />
                                    <p className="fun-facts-title"><span className="facts-numbers">5,000+</span><br />Happy Clients</p>
                                </div>
                                {/*end fun-facts-box */}
                                {/*begin fun-facts-box */}
                                <div className="col-md-2 fun-facts-box green text-center">
                                    <i className="bi bi-award-fill" />
                                    <p className="fun-facts-title"><span className="facts-numbers">92.45%</span><br />Delivery Rates</p>
                                </div>
                                {/*end fun-facts-box */}
                                {/*begin fun-facts-box */}
                                <div className="col-md-2 fun-facts-box red text-center">
                                    <i className="bi bi-basket3-fill" />
                                    <p className="fun-facts-title"><span className="facts-numbers">3000+</span><br />Virtual Fax Machines</p>
                                </div>
                                {/*end fun-facts-box */}
                                {/*begin fun-facts-box */}
                                <div className="col-md-2 fun-facts-box yellow text-center">
                                    <i className="bi bi-bell-fill" />
                                    <p className="fun-facts-title"><span className="facts-numbers">8500+</span><br />Fax Numbers</p>
                                </div>
                                {/*end fun-facts-box */}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/*end fun-facts */}
                    {/*begin team section */}
                    {/* <section className="section-white medium-padding-bottom" id="team">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>Meet Our Team</h2>
                                </div> */}
                    {/* <div className="col-sm-12 col-md-4 margin-top-30">
                                    <img src="http://placehold.it/555x555" className="team-img width-100" alt="pic" />
                                    <div className="team-item">
                                        <h4>Christina Hawkings</h4>
                                        <div className="team-info"><p>Head of SEO</p></div>
                                        <p>Johnathan is our  co-founder and has developed search strategies for a variety of clients for over 5 years.</p>
                                        <ul className="team-icon">
                                            <li><a href="#" className="twitter"><i className="bi bi-twitter" /></a></li>
                                            <li><a href="#" className="pinterest"><i className="bi bi-instagram" /></a></li>
                                            <li><a href="#" className="facebook"><i className="bi bi-mic" /></a></li>
                                            <li><a href="#" className="dribble"><i className="bi bi-envelope-open" /></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                    {/* <div className="col-sm-12 col-md-4 margin-top-30">
                                    <img src="http://placehold.it/555x555" className="team-img width-100" alt="pic" />
                                    <div className="team-item">
                                        <h4>Andreas Johansons</h4>
                                        <div className="team-info geen"><p>Marketing Manager</p></div>
                                        <p>Andres fell in love with marketing at the school and looks forward to being part of the industry for years.</p>
                                        <ul className="team-icon">
                                            <li><a href="#" className="twitter"><i className="bi bi-twitter" /></a></li>
                                            <li><a href="#" className="pinterest"><i className="bi bi-instagram" /></a></li>
                                            <li><a href="#" className="facebook"><i className="bi bi-mic" /></a></li>
                                            <li><a href="#" className="dribble"><i className="bi bi-envelope-open" /></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                    {/* <div className="col-sm-12 col-md-4 margin-top-30">
                                    <img src="http://placehold.it/555x555" className="team-img width-100" alt="pic" />
                                    <div className="team-item">
                                        <h4>Alexandra Smiths</h4>
                                        <div className="team-info green"><p>SEO Specialist</p></div>
                                        <p>Graduating with a degree in Spanish, English and French, she has always loved writing.</p>
                                        <ul className="team-icon">
                                            <li><a href="#" className="twitter"><i className="bi bi-twitter" /></a></li>
                                            <li><a href="#" className="pinterest"><i className="bi bi-instagram" /></a></li>
                                            <li><a href="#" className="facebook"><i className="bi bi-mic" /></a></li>
                                            <li><a href="#" className="dribble"><i className="bi bi-envelope-open" /></a></li>
                                        </ul>
                                    </div>
                                </div> */}
                    {/* </div>
                        </div>
                    </section> */}
                    {/*end team section*/}
                    {/*begin how-it-works section */}
                    <section className="section-grey" id="how-it-works">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row align-items-center">
                                {/*begin col-md-5 */}
                                <div className="col-md-5 col-sm-12">
                                    <h2>How It Works ?</h2>
                                    <p>You can enroll to our platform with a simple SignUp. You can also use Facebook, LinkedIn and Twitter to sign up. We offer a FLAT pricing model, therefore, you enjoy all services as a premium user on our platform. </p>
                                    {/*begin row*/}
                                    <div className="row">
                                        {/*begin col-md-4*/}
                                        <div className="col-md-4 col-sm-6">
                                            {/*begin testim-platform*/}
                                            <div className="testim-platform first">
                                                <p>LinkedIn</p>
                                                <div className="testim-rating">
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-half" />
                                                </div>
                                            </div>
                                            {/*end testim-platform*/}
                                        </div>
                                        {/*end col-sm-4*/}
                                        {/*begin col-md-4*/}
                                        <div className="col-md-4 col-sm-6">
                                            {/*begin testim-platform*/}
                                            <div className="testim-platform">
                                                <p>Google</p>
                                                <div className="testim-rating">
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star-fill" />
                                                    <i className="bi bi-star" />
                                                </div>
                                            </div>
                                            {/*end testim-platform*/}
                                        </div>
                                        {/*end col-sm-4*/}
                                    </div>
                                    {/*end row*/}
                                </div>
                                {/*end col-md-5 */}
                                {/*begin col-md-1 */}
                                <div className="col-md-1" />
                                {/*end col-md-1 */}
                                {/*begin col-md-6*/}
                                <div className="col-md-6">
                                    {/*begin accordion */}
                                    <div className="accordion accordion-flush" id="accordionOne">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <i className="bi bi-pencil-fill" /> Create Account
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionOne">
                                                <div className="accordion-body">
                                                    The first step is to create your account. You can try the social login options for quick account activation.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <i className="bi bi-bar-chart-line-fill" /> Setup Fax Numbers
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
                                                <div className="accordion-body">
                                                    You should up faxing numbers. You can still fax out without buying a number from us. However, you may need to verify your old Fax Numbers. Once verified, they can be added to your account. You can also port in Faxing Numbers to our network.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <i className="bi bi-hand-thumbs-up-fill" /> Start Faxing
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionOne">
                                                <div className="accordion-body">
                                                    Once the fax numbers set up is done, you can start faxing. You can also buy virtual fax devices for other businesses or your friends.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*end accordion */}
                                </div>
                                {/*end col-md-6*/}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end how-it-works section */}
                    {/*begin pricing section */}
                    <section className="section-white" id="pricing">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-12 */}
                                <div className="col-md-12 text-center padding-bottom-40">
                                    <h2>Flat pricing, pay as you go, You are all Premium !</h2>
                                </div>
                                {/*end col-md-12 */}
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="price-box">
                                        <ul className="pricing-list">
                                            <li className="price-title">Offnet Faxes</li>
                                            <li className="price-value">$0.05</li>
                                            <li className="price-subtitle">Per Page</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />Custom Charts</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />5 Mailboxes, 10 Gb Storage</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />Unlimited Free Dashboards</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />Access to all APIs</li>
                                            <li className="price-tag-line"><a href="#">FREE 15-DAY TRIAL</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="price-box">
                                        <div className="ribbon blue"><span>Popular</span></div>
                                        <ul className="pricing-list">
                                            <li className="price-title">P2P Faxes</li>
                                            <li className="price-value">$0.02</li>
                                            <li className="price-subtitle">Per Page</li>
                                            <li className="price-text strong"><i className="bi bi-check-circle-fill blue" /><strong>All Basic features</strong></li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />15 Mailboxes, 50 Gb Storage</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />Interactive Screen Sharing</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />Full Reports History</li>
                                            <li className="price-tag"><a href="#">FREE 15-DAY TRIAL</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="price-box">
                                        <ul className="pricing-list">
                                            <li className="price-title white-text">Fax Numbers</li>
                                            <li className="price-value white-text">$5.99</li>
                                            <li className="price-subtitle white-text">Number/Month</li>
                                            <li className="price-text white-text"><i className="bi bi-check-circle-fill blue" /><strong>All Standard Features</strong></li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />50 Mailboxes, 90 Gb Storage</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />Dedicated Account Manager</li>
                                            <li className="price-text"><i className="bi bi-check-circle-fill blue" />24/7 Priority Support</li>
                                            <li className="price-tag-line"><a href="#">FREE 15-DAY TRIAL</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end pricing section */}
                    {/*begin features section */}
                    <section className="section-grey medium-padding-bottom" id="features">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-12*/}
                                <div className="col-md-12 text-center">
                                    <h2>Amazing Features</h2>
                                </div>
                                {/*end col-md-12 */}
                            </div>
                            {/*end row */}
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="feature-box light-green">
                                        <i className="bi bi-gem" />
                                        <div className="feature-box-text">
                                            <h4>Support 24/7</h4>
                                            <p>Utise wisi enim nets venias, quis tation ullamcorper nets lobotis nisle consequat.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="feature-box light-blue">
                                        <i className="bi bi-people" />
                                        <div className="feature-box-text">
                                            <h4>User Friendly</h4>
                                            <p>Utise wisi enim nets venias, quis tation ullamcorper nets lobotis nisle consequat.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md 4*/}
                                <div className="col-md-4">
                                    <div className="feature-box orange">
                                        <i className="bi bi-bell" />
                                        <div className="feature-box-text">
                                            <h4>Notifications</h4>
                                            <p>Utise wisi enim nets venias, quis tation ullamcorper nets lobotis nisle consequat.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                            </div>
                            {/*end row */}
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="feature-box dark-blue">
                                        <i className="bi bi-gear" />
                                        <div className="feature-box-text">
                                            <h4>Accesibility</h4>
                                            <p>Utise wisi enim nets venias, quis tation ullamcorper nets lobotis nisle consequat.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="feature-box light-red">
                                        <i className="bi bi-cash-stack" />
                                        <div className="feature-box-text">
                                            <h4>Cost Control</h4>
                                            <p>Utise wisi enim nets venias, quis tation ullamcorper nets lobotis nisle consequat.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                                {/*begin col-md-4*/}
                                <div className="col-md-4">
                                    <div className="feature-box dark-green">
                                        <i className="bi bi-chat-left-text" />
                                        <div className="feature-box-text">
                                            <h4>Chat With Friends</h4>
                                            <p>Utise wisi enim nets venias, quis tation ullamcorper nets lobotis nisle consequat.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*end col-md-4 */}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </section>
                    {/*end features section */}
                    {/*begin section-white */}
                    {/* <section className="section-white no-padding-bottom">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 text-center">
                                    <p className="testimonial-single">“People who succeed in business aren't afraid to hear feedback from their customers - they actually thrive from it.”</p>
                                    <h5 className="margin-bottom-5">Johanna S. Richardson</h5>
                                    <p className="blue">CEO Essentials</p>
                                </div>
                                <div className="col-md-6">
                                    <img src="http://placehold.it/555x500" className="width-100" alt="pic" />
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/*begin blog */}
                    <section className="section-grey medium-padding-bottom" id="blog">
                        {/*begin container*/}
                        <div className="container">
                            {/*begin row*/}
                            <div className="row">
                                {/*begin col-md-12*/}
                                <div className="col-md-12 text-center">
                                    <h2>Our Blog</h2>
                                </div>
                                {/*end col-md-12*/}
                            </div>
                            {/*end row*/}
                            {/*begin row*/}
                            <div className="row">
                                {/*begin col-sm-4 */}
                                <div className="col-md-4">
                                    {/*begin blog-item */}
                                    <div className="blog-item">
                                        {/*begin popup image */}
                                        <div className="popup-wrapper">
                                            <div className="popup-gallery">
                                                <a href="#">
                                                    <img src="http://placehold.it/555x400" className="width-100" alt="pic" />
                                                    <span className="eye-wrapper2"><i className="bi bi-link-45deg" /></span>
                                                </a>
                                            </div>
                                        </div>
                                        {/*end popup image */}
                                        {/*begin blog-item_inner */}
                                        <div className="blog-item-inner">
                                            <h3 className="blog-title"><a href="#">The Guide To LinkedIn Ads</a></h3>
                                            <a href="#" className="blog-icons last"><i className="bi bi-card-text" /> Marketing &amp; Design — 11 Min Read</a>
                                            <p>Quis autem velis reprehender nets quiste voluptate velite estum quants etsamis sedit varias nets.</p>
                                        </div>
                                        {/*end blog-item-inner */}
                                    </div>
                                    {/*end blog-item */}
                                </div>
                                {/*end col-sm-4*/}
                                {/*begin col-sm-4 */}
                                <div className="col-md-4">
                                    {/*begin blog-item */}
                                    <div className="blog-item">
                                        {/*begin popup image */}
                                        <div className="popup-wrapper">
                                            <div className="popup-gallery">
                                                <a href="#">
                                                    <img src="http://placehold.it/555x400" className="width-100" alt="pic" />
                                                    <span className="eye-wrapper2"><i className="bi bi-link-45deg" /></span>
                                                </a>
                                            </div>
                                        </div>
                                        {/*end popup image */}
                                        {/*begin blog-item_inner */}
                                        <div className="blog-item-inner">
                                            <h3 className="blog-title"><a href="#">Affinity Designer Quick Start</a></h3>
                                            <a href="#" className="blog-icons last"><i className="bi bi-play-circle" /> SaaS Solutions — 25 Min Watch</a>
                                            <p>Quis autem velis reprehender nets quiste voluptate velite estum quants etsamis sedit varias nets.</p>
                                        </div>
                                        {/*end blog-item-inner */}
                                    </div>
                                    {/*end blog-item */}
                                </div>
                                {/*end col-sm-4*/}
                                {/*begin col-sm-4 */}
                                <div className="col-md-4">
                                    {/*begin blog-item */}
                                    <div className="blog-item">
                                        {/*begin popup image */}
                                        <div className="popup-wrapper">
                                            <div className="popup-gallery">
                                                <a href="#">
                                                    <img src="http://placehold.it/555x400" className="width-100" alt="pic" />
                                                    <span className="eye-wrapper2"><i className="bi bi-link-45deg" /></span>
                                                </a>
                                            </div>
                                        </div>
                                        {/*end popup image */}
                                        {/*begin blog-item_inner */}
                                        <div className="blog-item-inner">
                                            <h3 className="blog-title"><a href="#">Our Happy Team</a></h3>
                                            <a href="#" className="blog-icons last"><i className="bi bi-mic" /> Product Launch — 19 Min Listen</a>
                                            <p>Quis autem velis reprehender nets quiste voluptate velite estum quants etsamis sedit varias nets.</p>
                                        </div>
                                        {/*end blog-item-inner */}
                                    </div>
                                    {/*end blog-item */}
                                </div>
                                {/*end col-sm-4*/}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/*end blog */}
                    {/*begin contact */}
                    <section className="section-white" id="contact">
                        {/*begin container*/}
                        <div className="container">
                            {/*begin row*/}
                            <div className="row">
                                {/*begin col-md-6 */}
                                <Form />
                                {/*end col-md-6 */}
                                {/*begin col-md-6 */}
                                <div className="col-md-6 responsive-top-margins">
                                    <h3>How to find us</h3>
                                    <iframe className="contact-maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9050207912896!2d-0.14675028449633118!3d51.514958479636384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ad554c335c1%3A0xda2164b934c67c1a!2sOxford+St%2C+London%2C+UK!5e0!3m2!1sen!2sro!4v1485889312335" width={600} height={270} style={{ border: 0 }} allowFullScreen />
                                    <h5>Head Office</h5>
                                    <p className="contact-info"><i className="bi bi-geo-alt-fill" /> 10 Oxford Street, London, UK, E1 1EC</p>
                                    <p className="contact-info"><i className="bi bi-envelope-open-fill" /> <a href="mailto:contact@youremail.com">office@smart.co.uk</a></p>
                                    <p className="contact-info"><i className="bi bi-telephone-fill" /> +44 987 654 321</p>
                                </div>
                                {/*end col-md-6 */}
                            </div>
                            {/*end row*/}
                        </div>
                        {/*end container*/}
                    </section>
                    {/*end contact*/}
                    {/*begin footer */}
                    <div className="footer">
                        {/*begin container */}
                        <div className="container">
                            {/*begin row */}
                            <div className="row">
                                {/*begin col-md-7 */}
                                <div className="col-md-7">
                                    <p>© 2021 <span className="template-name">Smart</span>. Designed by <a href="https://1.envato.market/rkB1y" target="_blank">Epic-Themes</a></p>
                                </div>
                                {/*end col-md-7 */}
                                {/*begin col-md-5 */}
                                <div className="col-md-5">
                                    {/*begin footer_social */}
                                    <ul className="footer_social">
                                        <li>Follw us:</li>
                                        <li><a href="#" className="twitter"><i className="bi bi-twitter" /></a></li>
                                        <li><a href="#" className="instagram"><i className="bi bi-instagram" /></a></li>
                                        <li><a href="#" className="whatsapp"><i className="bi bi-whatsapp" /></a></li>
                                        <li><a href="#" className="google"><i className="bi bi-google" /></a></li>
                                        <li><a href="#" className="github"><i className="bi bi-github" /></a></li>
                                    </ul>
                                    {/*end footer_social */}
                                </div>
                                {/*end col-md-5 */}
                            </div>
                            {/*end row */}
                        </div>
                        {/*end container */}
                    </div>
                    {/*end footer */}
                </main>
            </div>
        </>
    );
}

export default Home;
