import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
  const [formData, setFormData] = useState({
    contact_names: '',
    contact_email: '',
    contact_phone: '',
    contact_message: '',
  });
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: '' }); // Clear error message on change
  };

  // Validate form
  const validateForm = () => {
    let errors = {};
    if (!formData.contact_names) errors.contact_names = 'Full Name is required';
    if (!formData.contact_email) {
      errors.contact_email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.contact_email)) {
      errors.contact_email = 'Email Address is invalid';
    }
    if (!formData.contact_phone) {
      errors.contact_phone = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formData.contact_phone)) {
      errors.contact_phone = 'Phone Number must be 10 digits';
    }
    if (!formData.contact_message) errors.contact_message = 'Message is required';
    return errors;
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    try {
      const response = await axios.post('https://emails.foipin.com/email/send_mail', {
        mail_templateID: 5,
        user_email: formData.contact_email,
        user_name: formData.contact_names,
        user_phone: formData.contact_phone,
        user_message: formData.contact_message,
      });
      if (response.status === 200) {
        setSuccess(true);
        setFormData({
          contact_names: '',
          contact_email: '',
          contact_phone: '',
          contact_message: '',
        });
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="col-md-6">
      <h3>Get in touch</h3>
      {success && (
        <p className="contact_success_box">
          We received your message and you'll hear from us soon. Thank You!
        </p>
      )}
      <form id="contact-form" className="contact" onSubmit={handleSubmit}>
        <input
          className="contact-input white-input"
          name="contact_names"
          placeholder="Full Name*"
          type="text"
          value={formData.contact_names}
          onChange={handleChange}
        />
        {error.contact_names && <p className="error-msg">{error.contact_names}</p>}

        <input
          className="contact-input white-input"
          name="contact_email"
          placeholder="Email Address*"
          type="email"
          value={formData.contact_email}
          onChange={handleChange}
        />
        {error.contact_email && <p className="error-msg">{error.contact_email}</p>}

        <input
          className="contact-input white-input"
          name="contact_phone"
          placeholder="Phone Number*"
          type="text"
          value={formData.contact_phone}
          onChange={handleChange}
        />
        {error.contact_phone && <p className="error-msg">{error.contact_phone}</p>}

        <textarea
          className="contact-commnent white-input"
          rows={2}
          cols={20}
          name="contact_message"
          placeholder="Your Message..."
          value={formData.contact_message}
          onChange={handleChange}
        />
        {error.contact_message && <p className="error-msg">{error.contact_message}</p>}

        <input
          value="Send Message"
          id="submit-button"
          className="contact-submit"
          type="submit"
        />
      </form>
    </div>
  );
}

export default ContactForm;
