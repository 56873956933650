import './App.css';
import './assets/css/style-magnific-popup.css';
import './assets/css/animate.css';
import './assets/css/bootstrap-icons.css';
import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap.min.css.map';
import './assets/css/style-magnific-popup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Home from './COMPONENTS/Home';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path='/' element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
